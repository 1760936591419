import { LogoBusiness } from "assets/vectors/logo";
import clsx from "clsx";

const styles = {
    container: clsx(
        "h-screen",
        "w-full",
        "bg-[#ebeefd]",
        "z-[999]",
        "absolute",
        "top-0",
        "left-0",
        "right-0",
    ),
    logoDiv: clsx(
        "absolute left-1/2 top-1/2",
        "translate-x-[-50%] translate-y-[-50%]",
    ),
    logo: "h-[64px] color-white",
};

export const PageLoading = ({
    display = true,
}: {
    display: boolean;
}): JSX.Element => {
    return (
        <div
            className={clsx(
                styles.container,
                display ? "visible opacity-100" : "invisible opacity-0",
            )}
        >
            <div className={styles.logoDiv}>
                <LogoBusiness className={styles.logo} />
            </div>
        </div>
    );
};
