import {
    useState,
    useEffect,
    useContext,
    createContext,
    ReactNode,
} from "react";
import {
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    updateProfile,
    signOut,
    User,
    GoogleAuthProvider,
    FacebookAuthProvider,
    signInWithPopup,
    onIdTokenChanged,
    Auth,
} from "firebase/auth";

type AuthContext = {
    user: User | null;
    isCourtsite: boolean;
    isInitialised: boolean;
    signin?: (email: string, password: string) => Promise<User>;
    signup?: (email: string, password: string, name: string) => Promise<User>;
    signout?: () => Promise<void>;
    initGoogleAuth?: () => Promise<User>;
    initFacebookAuth?: () => Promise<User>;
};

const authContext = createContext<AuthContext>({
    user: null,
    isCourtsite: false,
    isInitialised: false,
});

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export const FirebaseAuthProvider = ({
    children,
    auth,
}: {
    children: ReactNode;
    auth: Auth;
}): JSX.Element => {
    const ctx = useProvideAuth(auth);
    return <authContext.Provider value={ctx}>{children}</authContext.Provider>;
};

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = (): AuthContext => {
    return useContext(authContext);
};

// Provider hook that creates auth object and handles state
const useProvideAuth = (auth: Auth): AuthContext => {
    const [user, setUser] = useState<User | null>(null);
    const [isInitialised, setIsInitialised] = useState<boolean>(false);

    const isCourtsite = user?.email?.includes("courtsite.my") ?? false;
    // Wrap any Firebase methods we want to use making sure ...
    // ... to save the user to state.
    const signin = (email: string, password: string): Promise<User> => {
        return signInWithEmailAndPassword(auth, email, password).then(
            (userCredential) => {
                setUser(userCredential.user);
                return userCredential.user;
            },
        );
    };

    const signup = async (
        email: string,
        password: string,
        name: string,
    ): Promise<User> => {
        const userCredential = await createUserWithEmailAndPassword(
            auth,
            email,
            password,
        );
        setUser(userCredential.user);

        await updateProfile(userCredential.user, {
            displayName: name,
        });

        const user = {
            ...userCredential.user,
            displayName: name,
        };
        setUser(user);
        return user;
    };

    const signout = (): Promise<void> => {
        return signOut(auth);
    };

    const initGoogleAuth = (): Promise<User> => {
        const provider = new GoogleAuthProvider();

        return signInWithPopup(auth, provider).then((userCredential) => {
            setUser(userCredential.user);
            return userCredential.user;
        });
    };

    const initFacebookAuth = (): Promise<User> => {
        const provider = new FacebookAuthProvider();

        return signInWithPopup(auth, provider).then((userCredential) => {
            setUser(userCredential.user);
            return userCredential.user;
        });
    };

    // Subscribe to user on mount
    // Because this sets state in the callback it will cause any ...
    // ... component that utilizes this hook to re-render with the ...
    // ... latest auth object.
    useEffect(() => {
        const unsubscribe = onIdTokenChanged(auth, (user) => {
            setUser(user);
            setIsInitialised(true);
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    });

    // Return the user object and auth methods
    return {
        user,
        isCourtsite,
        isInitialised,
        signin,
        signup,
        signout,
        initGoogleAuth,
        initFacebookAuth,
    };
};
