import {
    ApolloClient,
    HttpLink,
    ApolloLink,
    InMemoryCache,
    defaultDataIdFromObject,
    NormalizedCacheObject,
} from "@apollo/client";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";

import { LocalStorageWrapper, persistCache } from "apollo3-cache-persist";
import { sha256 } from "crypto-hash";

import { captureException } from "@sentry/nextjs";
import { relayStylePagination } from "@apollo/client/utilities";
import { Auth } from "firebase/auth";

const newCache = async () => {
    const cache = new InMemoryCache({
        dataIdFromObject: (res) => {
            if (res.__typename && res.uid) {
                return `${res.__typename}:${JSON.stringify(res.uid)}`;
            }
            return defaultDataIdFromObject(res);
        },
        typePolicies: {
            Query: {
                fields: {
                    settlements: relayStylePagination(["tenantId", "filters"]),
                },
            },
        },
    });

    await persistCache({
        cache,
        storage: new LocalStorageWrapper(window.localStorage),
    });

    return cache;
};

const constructLinks = (fbAuth: Auth) => {
    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            graphQLErrors.map((error) => {
                // eslint-disable-next-line no-console
                console.error(
                    `[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`,
                );
                captureException(
                    new Error(`[GraphQL error]: Message: ${error.message}`),
                );
            });
        }
        if (networkError) {
            // eslint-disable-next-line no-console
            console.error(`[Network error]: ${networkError}`);
        }
    });

    const apqLink = createPersistedQueryLink({ sha256 });

    const httpLink = new HttpLink({
        uri: process.env.NEXT_PUBLIC_ENJIN_PELANGGAN_PROKSI_API_URL,
    });

    const authLink = setContext(async (_, { headers }) => {
        const currentUser = fbAuth.currentUser;
        const token = await currentUser?.getIdToken();
        return {
            headers: {
                ...headers,
                Authorization: token ? `Firebase ${token}` : "",
            },
        };
    });

    return ApolloLink.from([errorLink, authLink, apqLink, httpLink]);
};

export const createClient = async (
    fbAuth: Auth,
): Promise<ApolloClient<NormalizedCacheObject>> => {
    return new ApolloClient({
        cache: await newCache(),
        link: constructLinks(fbAuth),
    });
};
