import type { ReactElement, ReactNode } from "react";
import { useEffect, useState } from "react";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import {
    ApolloClient,
    ApolloProvider,
    NormalizedCacheObject,
} from "@apollo/client";
import { PageLoading } from "components/Loading";

import { FirebaseAuthProvider } from "lib/firebase/hooks";
import { createClient } from "lib/apollo/client";

import { useRouter } from "next/router";
import { useAuth } from "lib/firebase/hooks";

import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "components/DatePicker/react-datepicker.css";
import "styles/global.css";
import { auth } from "lib/firebase/client";

type NextPageWithLayout = NextPage & {
    getLayout?: (page: ReactElement) => ReactNode;
    isPrivate?: boolean;
};

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
};

const EnjinSetiausaha = ({
    Component,
    pageProps,
}: AppPropsWithLayout): JSX.Element => {
    const getLayout = Component.getLayout ?? ((page) => page);
    const isPrivate = Component.isPrivate;
    const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();

    useEffect(() => {
        createClient(auth).then(setClient);
    }, []);

    if (!client) {
        return <div />;
    }

    return (
        <FirebaseAuthProvider auth={auth}>
            <ApolloProvider client={client}>
                <PrivateRoute isPrivate={isPrivate}>
                    <ToastContainer position="top-center" transition={Slide} />
                    {getLayout(<Component {...pageProps} />)}
                </PrivateRoute>
            </ApolloProvider>
        </FirebaseAuthProvider>
    );
};

const PrivateRoute = ({
    children,
    isPrivate,
}: {
    children: ReactNode;
    isPrivate?: boolean;
}): JSX.Element => {
    const router = useRouter();
    const { isInitialised, user } = useAuth();

    if (!isInitialised && isPrivate) {
        return <PageLoading display={true} />;
    }

    if (!user && isPrivate) {
        router.push("/login");
        return <PageLoading display={true} />;
    }

    return <div>{children}</div>;
};
export default EnjinSetiausaha;
